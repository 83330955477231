import { Box } from '@packages/shared/src/components/Box/Box';
import { Typography } from '@packages/shared/src/components/Typography/Typography';
import { SoftHyphenInjector } from '../SoftHyphenInjector';
import type { OverlayCircleType } from './types';

/**
 * This component shows editorial content of a teaser.
 *
 * @param data Object contains data
 * @returns JSX.Element to be placed in PageLayout
 */
export const OverlayCircleItem = ({ data }: { data: OverlayCircleType }) => {
  const {
    field_text_01: text01,
    field_text_02: text02,
    field_text_03: text03,
    behavior_paragraph_invert_font_color: styleIsNegativeTextColor = false,
  } = data;

  return (
    <Box
      sx={{
        display: 'flex',
        color: styleIsNegativeTextColor ? 'text.dark' : 'text.light',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        lineHeight: '0.875rem',
        '@container (min-width: 900px)': {
          '&': {
            lineHeight: '1.688rem',
          },
        },
        '& .soft-hyphen-injector': {
          position: 'relative',
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'inline-block',
        },
      }}
      component="span"
    >
      {text01 && (
        <Typography
          component="span"
          sx={{
            fontSize: '0.75rem',
            lineHeight: '0.875rem',
            '@container (min-width: 900px)': {
              '&': {
                fontSize: '1.25rem',
                lineHeight: '1.688rem',
              },
            },
          }}
        >
          <SoftHyphenInjector text={text01} />
        </Typography>
      )}
      {text02 && (
        <Typography
          component="span"
          sx={{
            fontSize: '2.563rem',
            lineHeight: '2.563rem',
            '@container (min-width: 900px)': {
              '&': {
                fontSize: '5.313rem',
                lineHeight: '5.75rem',
              },
            },
          }}
        >
          <SoftHyphenInjector text={text02} />
        </Typography>
      )}
      {text03 && (
        <Typography
          component="span"
          sx={{
            fontSize: '0.75rem',
            lineHeight: '0.875rem',
            '@container (min-width: 900px)': {
              '&': {
                fontSize: '1.25rem',
                lineHeight: '1.688rem',
              },
            },
          }}
        >
          <SoftHyphenInjector text={text03} />
        </Typography>
      )}
    </Box>
  );
};
